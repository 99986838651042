<template>
  <div id="all-time-dropdown">
    <b-card>
      <div class="row mb-2 text-right">
        <div
          class="col-md-12 p-0 d-flex align-items-center justify-content-end"
        >
          <b-button
            @click="modalShowForQRCode = !modalShowForQRCode"
            class="btn mt-1 mt-lg-0 add-btn d-block mr-1"
          >
            <div class="d-flex flex-row align-items-center">
              <span class="pr-1"> Print QR Code </span>
              <span>
                <img
                  src="@/assets/images/icons/qr-code-scan.png"
                  alt="add icon"
                  height="20"
                  width="20"
                />
              </span>
            </div>
          </b-button>
          <b-button
            v-if="$can('send employee to xero', 'Employee')"
            @click.prevent="sendAllEmployeeToXero"
            class="btn mt-1 mt-lg-0 add-btn d-block mr-1"
          >
            <div class="d-flex flex-row align-items-center">
              <span class="pr-1"> Send Employees To Xero </span>
              <span>
                <img
                  src="@/assets/images/icons/paper.png"
                  alt="add icon"
                  height="20"
                  width="20"
                />
              </span>
            </div>
          </b-button>
          <a
            v-if="$can('import', 'Employee')"
            href="https://grypas.inflack.xyz/grypas-api/bulk/Employee_Data_Sample.xlsx"
            class="btn mt-1 mt-lg-0 add-btn d-block mr-1"
          >
            <div class="d-flex flex-row align-items-center">
              <span class="pr-1"> Download Sample </span>
              <span>
                <img
                  src="@/assets/images/icons/download-1.png"
                  alt="add icon"
                  height="20"
                  width="20"
                />
              </span>
            </div>
          </a>
          <router-link to="/employee-import" v-if="$can('import', 'Employee')">
            <b-button class="btn mt-1 mt-lg-0 add-btn d-block mr-1">
              <div class="d-flex flex-row align-items-center">
                <span class="pr-1"> Import Employee </span>
                <span>
                  <img
                    src="@/assets/images/icons/upload-1.png"
                    alt="add icon"
                    height="20"
                    width="20"
                  />
                </span>
              </div>
            </b-button>
          </router-link>
          <b-button
            v-if="$can('create', 'Employee')"
            class="btn mt-1 mt-lg-0 add-btn d-block"
          >
            <router-link to="/employee-create">
              <div class="d-flex flex-row align-items-center">
                <span class="pr-1"> Add Employee </span>
                <span>
                  <img
                    src="@/assets/images/icons/add-icon.png"
                    alt="add icon"
                    height="20"
                    width="20"
                  />
                </span>
              </div>
            </router-link>
          </b-button>
        </div>
      </div>
      <div class="row d-flex align-items-center">
        <div class="col-md-12">
          <div class="row">
            <div
              class="col-md-4"
              v-if="logedinUser.roles[0].name === 'superadmin'"
            >
              <label for="" class="label-font">Subsidiary Company:</label>
              <b-form-group>
                <v-select
                  v-model="query.company"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="companies"
                  label="name"
                  placeholder="Select Subsidiary Company"
                  @input="getCustomers"
                />
              </b-form-group>
            </div>
            <div
              class="col-md-4"
              v-if="
                logedinUser.roles[0].name === 'superadmin' ||
                logedinUser.roles[0].name === 'admin'
              "
            >
              <label for="" class="label-font">Client:</label>
              <b-form-group>
                <v-select
                  v-model="query.customer"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="customers"
                  label="name"
                  placeholder="Select Client"
                  @input="getEmployees"
                />
              </b-form-group>
            </div>

            <div class="col-md-4">
              <label for="" class="label-font">Search:</label>
              <b-form-group>
                <b-form-input
                  v-debounce:300="getEmployees"
                  v-model="query.name"
                  class="rounded search-input bg-light"
                  placeholder="Search..."
                  type="text"
                  size="md"
                />
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-10">
              <div class="d-flex flex-row justify-content-start">
                <label for="" class="label-font">Status:</label>
                <button variant="primary" @click="changeStatus(0)" class="btn">
                  <span class="active-success"></span>
                  <span :class="query.status === 0 ? 'has-border' : ''"
                    >Active
                    <b-badge variant="success">{{
                      active_count
                    }}</b-badge></span
                  >
                </button>
                <button
                  v-if="this.logedinUser.roles[0].name !== 'customer'"
                  @click="changeStatus(1)"
                  class="btn"
                >
                  <span class="active-warning"></span>
                  <span :class="query.status === 1 ? 'has-border' : ''"
                    >Inactive
                    <b-badge variant="warning">{{
                      inactive_count
                    }}</b-badge></span
                  >
                </button>
                <button
                  v-if="this.logedinUser.roles[0].name !== 'customer'"
                  @click="changeStatus(2)"
                  class="btn"
                >
                  <span class="active-danger"></span>
                  <span :class="query.status === 2 ? 'has-border' : ''"
                    >Compnay/Client Free
                    <b-badge variant="danger">{{ free_count }}</b-badge></span
                  >
                </button>
                <button
                  v-if="this.logedinUser.roles[0].name === 'superadmin'"
                  @click="changeStatus(3)"
                  class="btn"
                >
                  <span class="active-info"></span>
                  <span :class="query.status === 3 ? 'has-border' : ''"
                    >Candidate
                    <b-badge variant="info">{{
                      candidate_count
                    }}</b-badge></span
                  >
                </button>
              </div>
            </div>
            <!-- <div v-if="query.status !== 0" class="col-md-4">
              <label for="" class="label-font">Status:</label>
              <b-form-group>
                <v-select
                  v-model="query.selectedStatus"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statuses"
                  label="name"
                  :clearable="false"
                  placeholder="Select Status"
                  @input="getEmployees"
                />
              </b-form-group>
            </div> -->
          </div>
        </div>
      </div>
    </b-card>
    <div>
      <b-card>
        <b-table
          :items="employees"
          :per-page="query.per_page"
          :fields="query.status === 3 ? tableFieldsCandidate : tableFields"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative bg-white"
          :busy.sync="loading"
          striped
        >
          <template #cell(sl)="row">
            <div
              :class="`p-1 ${
                row.item.status === 0 && row.item.user_status === 'active'
                  ? 'active-success'
                  : row.item.status === 0 && row.item.user_status === 'free'
                  ? 'active-danger'
                  : query.status === 3
                  ? 'active-info'
                  : 'active-warning'
              }`"
            >
              <span>
                {{ row.index + from }}
              </span>
            </div>
          </template>
          <template #cell(serial_number)="row">
            <span>
              <router-link
                v-if="$can('view', 'Employee')"
                :to="`/employee-preview/${row.item.id}`"
                class="text-primary"
              >
                {{ row.item.serial_number }}
              </router-link>
              <span v-else>{{ row.item.name ? row.item.name : "N/A" }}</span>
            </span>
          </template>
          <template #cell(name)="row">
            <div v-if="row.item.employee">
              <router-link
                v-if="$can('view', 'Employee')"
                :to="`/employee-preview/${row.item.id}`"
                class="text-primary"
              >
                {{ row.item.name ? row.item.name : "N/A" }}
              </router-link>
              <span v-else>{{ row.item.name ? row.item.name : "N/A" }}</span>
            </div>
          </template>

          <template
            v-if="
              logedinUser.roles[0].name != 'admin' &&
              logedinUser.roles[0].name != 'customer'
            "
            #cell(AssignedBy)="row"
          >
            {{
              row.item.employee_to_company &&
              row.item.employee_to_company.length > 0
                ? getAssignedTo(row.item.employee_to_company)
                : "N/A"
            }}
          </template>
          <template #cell(AssignedTo)="row">
            {{
              row.item.employee_customer &&
              row.item.employee_customer.length > 0
                ? getAssignedTo(row.item.employee_customer)
                : "N/A"
            }}
          </template>
          <template #cell(Address)="row">
            <span>{{
              row.item.employee && row.item.employee.address
                ? row.item.employee.address
                : "N/A"
            }}</span>
            <span v-if="row.item.employee && row.item.employee.city">, {{ row.item.employee.city }}</span>
          </template>
          <!-- <template #cell(Expertise)="row">
            {{ row.item.sector ? row.item.sector.name : "N/A" }}
          </template> -->
          <template #cell(XeroStatus)="row">
            {{
              row.item.employee && row.item.employee.is_xero === 1
                ? "Sync With Xero"
                : "Not Sync With Xero"
            }}
          </template>

          <template #cell(action)="row">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                v-if="logedinUser.roles[0].name === 'superadmin'"
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item>
                  <div>
                    <b-link
                      v-if="$can('update', 'Employee')"
                      :to="`/employee-profile-update/${row.item.id}`"
                      class="btn btn-info btn-sm mx-1"
                    >
                      Edit
                    </b-link>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item>
                  <div>
                    <b-link
                      v-if="$can('view', 'Employee')"
                      :to="`/employee-profile-print/${row.item.id}`"
                      class="btn btn-secondary btn-sm mx-1"
                    >
                      Print
                    </b-link>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    row.item.register !== 'candidate' &&
                    row.item.employee &&
                    row.item.employee.is_xero !== 1 &&
                    $can('send employee to xero', 'Employee')
                  "
                >
                  <div>
                    <b-button
                      @click.prevent="syncWithXero(row.item.employee.id)"
                      class="mx-1"
                      variant="primary"
                      size="sm"
                    >
                      Sync With Xero
                    </b-button>
                  </div>
                </b-dropdown-item>

                <b-dropdown-item>
                  <b-button
                    v-if="$can('suspend', 'Employee')"
                    @click.prevent="deleteUser(row.item.id)"
                    class="btn btn-danger btn-sm mx-1"
                  >
                    Delete
                  </b-button>
                </b-dropdown-item>
                <b-dropdown-item>
                  <b-button
                    v-if="row.item.status == '0' && $can('suspend', 'Employee')"
                    @click.prevent="suspendAlert(row.item.id)"
                    class="btn btn-warning btn-sm mx-1"
                  >
                    Suspend
                  </b-button>
                  <b-button
                    v-if="
                      row.item.status == '1' &&
                      $can('withdrawal suspension', 'Employee')
                    "
                    @click.prevent="activeUser(row.item.id)"
                    class="btn btn-success btn-sm mx-1"
                  >
                    Withdrawal Suspension
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
              <div class="text-center" v-else>
                <b-link
                  v-if="$can('view', 'Employee')"
                  :to="`/employee-preview/${row.item.id}`"
                  class="btn btn-info btn-sm"
                >
                  View
                </b-link>
              </div>
            </span>
          </template>
        </b-table>
        <b-pagination
          class="mt-3 p-1 d-flex justify-content-end bg-white"
          v-if="total > query.per_page"
          v-model="query.page"
          :total-rows="total"
          :per-page="query.per_page"
          aria-controls="my-table"
          @change="getEmployees($event)"
          pills
          size="lg"
          page-class="info"
        ></b-pagination>
      </b-card>
    </div>

    <b-modal
      v-model="modalShowForQRCode"
      @ok="downloadQRCode"
      title="Download QR Code"
      ok-title="Download"
      size="md"
    >
      <div id="print-me">
        <h1 class="text-center mb-2">Scan Me!</h1>
        <vue-q-r-code-component
          class="d-flex justify-content-center"
          :text="`${appUrl}/register`"
          error-level="L"
          color="#06235e"
          :size="350"
        ></vue-q-r-code-component>
        <h3 class="text-center mt-2">Complete Your Registration.</h3>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { getLogedInUser } from "@/utils/auth";
import { VUE_APP_URL } from "@/utils/var";
import { toast } from "@/utils/toast";
import {
  BAvatar,
  BBadge,
  BButton,
  BCalendar,
  BCard,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  BTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import VueQRCodeComponent from "vue-qrcode-component";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
export default {
  components: {
    BAvatar,
    BBadge,
    BButton,
    BCalendar,
    BCard,
    BCol,
    BContainer,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BFormInput,
    BFormSelect,
    BLink,
    BMedia,
    BPagination,
    BRow,
    BTable,
    BTooltip,
    BFormGroup,
    BPagination,
    vSelect,
    VueQRCodeComponent,
  },
  data() {
    return {
      appUrl: VUE_APP_URL,
      modalShowForQRCode: false,
      tableFields: [
        { key: "sl" },
        { key: "serial_number", label: "IDN", sortable: true },
        { key: "name", sortable: true },
        {
          key:
            JSON.parse(getLogedInUser()).roles[0].name === "superadmin"
              ? "AssignedBy"
              : "",
        },
        { key: "AssignedTo" },
        {
          key:
            JSON.parse(getLogedInUser()).roles[0].name === "customer"
              ? ""
              : "Address",
        },
        { key: "phone" },
        // { key: "Expertise" },
        { key: "XeroStatus" },
        { key: "action", label: "Action" },
      ],
      tableFieldsCandidate: [
        { key: "sl" },
        { key: "serial_number", label: "IDN", sortable: true },
        { key: "name", sortable: true },
        {
          key:
            JSON.parse(getLogedInUser()).roles[0].name === "customer"
              ? ""
              : "Address",
        },
        { key: "phone" },
        { key: "email" },
        { key: "action", label: "Action" },
      ],
      sector: {
        name: "",
      },
      duties: [],
      employees: [],
      companies: [],
      customers: [],
      loading: false,
      query: {
        keyword: "",
        page: 1,
        per_page: 20,
        name: "",
        sector: null,
        status: 0,
      },
      sortBy: "id",
      isSortDirDesc: true,
      total: 0,
      from: 0,
      logedinUser: JSON.parse(getLogedInUser()),
      loading: false,
      active_count: "",
      inactive_count: "",
      free_count: "",
      candidate_count: "",
      statuses: [
        { id: 1, name: "Inactive" },
        { id: 2, name: "Company/Client Free" },
      ],
    };
  },
  created() {
    this.getCompanies();
    if (this.logedinUser.roles[0].name == "admin") {
      let roles = this.logedinUser.roles.map((role) => {
        return role.name;
      });
      if (roles.includes("supervisor")) {
        this.query.company_id = this.logedinUser.supervisor.company_id;
        this.query.company = this.logedinUser.supervisor.company;
      } else {
        this.query.company_id = this.logedinUser.id;
        this.query.company = this.logedinUser;
      }

      this.getCustomers();
    } else if (this.logedinUser.roles[0].name == "customer") {
      this.query.customer_id = this.logedinUser.id;
      this.query.customer = this.logedinUser;
      this.getCustomers();
    } else {
      this.getEmployees();
    }
    this.getDuties();
  },
  methods: {
    async getDuties() {
      let res = await this.$store.dispatch("duty/getDuties");
      this.duties = res.data.data;
    },
    getCompanies() {
      this.$store
        .dispatch("company/getCompaniesForDropdown")
        .then(({ data }) => {
          this.companies = data;
        });
    },
    async deleteUser(item) {
      await this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete the employee.", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            let del = await this.$store.dispatch("user/deleteUser", item);
            if (del.status_code == 200) {
              toast(
                "Deleted",
                "AlertTriangleIcon",
                "danger",
                "Employee deleted !"
              );
              this.getEmployees();
            }
          }
        })
        .catch((err) => {
          toast(
            "Error",
            "AlertTriangleIcon",
            "danger",
            err?.response?.data?.message || "Can't Delete! Please try again."
          );
        });
    },
    async getCustomers(e) {
      let query = {};
      if (this.query.company && this.query.company.id) {
        query.user_id = this.query.company.id;
      } else {
        query.user_id = "";
      }
      if (query.user_id) {
        await this.$store
          .dispatch("customer/getCustomersForDropdown", query)
          .then((response) => {
            // reassigned the customers to the new array which has the customer_to_company.length > 0 in customer object
            this.customers = response.data;
          });
      }

      this.getEmployees();
    },
    changeStatus(status) {
      this.query.status = status;
      this.query.page = 1;
      this.getEmployees();
    },
    getEmployees(e) {
      this.loading = true;
      if (this.logedinUser.roles[0].name == "admin") {
        let roles = this.logedinUser.roles.map((role) => {
          return role.name;
        });
        if (roles.includes("supervisor")) {
          this.query.company_id = this.logedinUser.supervisor.company_id;
          this.query.company = this.logedinUser.supervisor.company;
        } else {
          this.query.company_id = this.logedinUser.id;
        }
      }
      if (this.logedinUser.roles[0].name == "customer") {
        this.query.customer_id = this.logedinUser.id;
        this.query.company = this.logedinUser.customer_to_company[0];
      }
      if (e && typeof e === "number") {
        this.query.page = e;
      } else {
        this.query.page = 1;
      }
      if (this.query.company && this.query.company.id) {
        this.query.company_id = this.query.company.id;
      } else {
        this.query.company_id = "";
      }
      if (this.query.customer && this.query.customer.id) {
        this.query.customer_id = this.query.customer.id;
      } else {
        this.query.customer_id = "";
      }
      if (this.query.sector && this.query.sector.id) {
        this.query.sector_id = this.query.sector.id;
      } else {
        this.query.sector_id = "";
      }
      if (this.query.selectedStatus && this.query.selectedStatus.id) {
        this.query.status = this.query.selectedStatus.id;
      } else {
        this.query.status = this.query.status;
      }
      if (this.query.status === 3) {
        this.query.candidate = "candidate";
      } else {
        this.query.candidate = "";
      }
      // delete company and customer from query
      let company = this.query.company;
      let customer = this.query.customer;
      delete this.query.company;
      delete this.query.customer;
      this.$store
        .dispatch("employee/getEmployees", this.query)
        .then((res) => {
          this.employees = res.data.data;
          this.total = res.data.total;
          this.from = res.data.from;
          this.loading = false;
          this.active_count = res.active_count;
          this.inactive_count = res.inactive_count;
          this.free_count = res.free_count;
          this.candidate_count = res.candidate_count;
        })
        .catch((err) => {
          this.loading = false;
        });
      // reassign company and customer to query
      this.query.company = company;
      this.query.customer = customer;
    },

    getAssignedTo(companies) {
      let comps = [];
      let assignedTo = companies.map((company) => {
        if (this.logedinUser.roles[0].name === "customer") {
          if (company.id === this.logedinUser.id) {
            comps.push(company.name);
          }
        } else {
          comps.push(company.name);
        }
      });

      return comps.join(", ");
    },
    suspendAlert(item) {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to suspend the Employee.",
          {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "success",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.suspendUser(item);
          }
        })
        .catch((err) => {
          console.log(err, "cancel");
          // An error occurred
        });
    },
    suspendUser(item) {
      // statusChange
      let data = {
        id: item,
        status: 1,
      };

      this.$store
        .dispatch("user/statusChange", data)
        .then((response) => {
          if (response.status_code === 200) {
            toast("Suspended", "CheckCircleIcon", "success", "User Suspended.");
            this.getEmployees();
          }
        })
        .catch((error) => {
          toast(
            "Error",
            "AlertTriangleIcon",
            "danger",
            error.response?.data?.message || "Error Suspending User"
          );
        });
    },
    activeUser(item) {
      // statusChange
      let data = {
        id: item,
        status: 0,
      };
      this.$store
        .dispatch("user/statusChange", data)
        .then((response) => {
          if (response.status_code === 200) {
            toast("Activated", "CheckCircleIcon", "success", "User Activated");
            this.getEmployees();
          }
        })
        .catch((error) => {
          toast(
            "Error",
            "AlertTriangleIcon",
            "danger",
            error.response?.data?.message || "Error Activing User"
          );
        });
    },
    syncWithXero(id) {
      if (!id) {
        toast(
          "Error",
          "AlertTriangleIcon",
          "danger",
          "Error Syncing With Xero"
        );
        return;
      }
      this.$store
        .dispatch("employee/syncWithXero", id)
        .then((response) => {
          if (response.status_code === 200) {
            toast(
              "Synced",
              "CheckCircleIcon",
              "success",
              "Employee Synced With Xero"
            );
            this.getEmployees();
          }
        })
        .catch((error) => {
          toast(
            error.response?.data?.message,
            "AlertTriangleIcon",
            "danger",
            error.response?.data?.error || "Error Syncing With Xero"
          );
        });
    },
    sendAllEmployeeToXero() {
      // show confirm dialog
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to send all employees to xero?", {
          title: "Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$store
              .dispatch("employee/sendAllEmployeeToXero")
              .then((response) => {
                if (response.data.status_code === 200) {
                  toast(
                    "Synced",
                    "CheckCircleIcon",
                    "success",
                    response?.data?.message || "Employee Synced With Xero"
                  );
                  this.getEmployees();
                }
              })
              .catch((error) => {
                toast(
                  "Error",
                  "AlertTriangleIcon",
                  "danger",
                  error.response?.data?.message || "Error Syncing With Xero"
                );
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    downloadQRCode() {
      htmlToImage
        .toPng(document.getElementById("print-me"))
        .then(function (dataUrl) {
          var link = document.createElement("a");
          link.download = "grypas-register-qr-code.png";
          link.href = dataUrl;
          link.click();
        });
    },
  },
};
</script>

<style scoped crossorigin="anonymous">
.active-indicator {
  background-color: #50c878;
  height: 40px;
  width: 4px;
  padding: 0;
}

.Unengaged-indicator {
  background-color: #ff4f5d;
  height: 40px;
  width: 4px;
}

#indicator-unengaged [dir] .table th,
[dir] .table td {
  padding: 0rem 0rem;
}

#indicator-active [dir] .table th,
[dir] .table td {
  padding: 0rem 0rem;
}

.label-font {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  margin-top: 10px;
}

.active-success {
  border-left: 4px solid #50c878;
  margin-right: 5px;
}

.active-danger {
  border-left: 4px solid #ff4f5d;
  margin-right: 5px;
}

.active-warning {
  border-left: 4px solid #ff9f43;
  margin-right: 5px;
}

.active-info {
  border-left: 4px solid #00cfe8;
  margin-right: 5px;
}

[dir="ltr"] .custom-select {
  /* 14 bold */

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */
  color: #7190ef !important;
}

.add-btn {
  border-color: white !important;

  /* Gradient */

  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.has-border {
  border: 1px solid #7190ef;
  border-radius: 5px;
  padding: 5px 10px;
  margin-left: 5px;
  color: #7190ef;
}

.dropdown-menu {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(-77px, -162px, 0px) !important;
}
.modal-content img {
  display: block !important;
  margin: 0 auto !important;
}

#print-me {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
