<template>
  <b-row>
    <b-col>
      <employee-list-table />
    </b-col>
  </b-row>
</template>
<script>
import {
BCalendar, BCol, BContainer, BRow
} from 'bootstrap-vue';

import EmployeeListTable from './components/employee-list-table.vue';

export default {
  components: {
    EmployeeListTable,
    BRow,
    BCol,
    BContainer,
    BCalendar,
  },
}
</script>
